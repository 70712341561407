import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, title, children }) {
  const data = useStaticQuery(graphql`
    query SiteMeta {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          templateKey
          title
          description
        }
      }
    }
  `);

  const metaDescription =
    description || data.markdownRemark.frontmatter.description;
  const defaultTitle = data.markdownRemark.frontmatter.title;

  return (
    <>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta
        property="og:title"
        content={title ? `${title} | ${defaultTitle}` : defaultTitle}
      />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {/* <meta
        name="og:image"
        content={data.markdownRemark.frontmatter.image.publicURL}
      /> */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={title ? `${title} | ${defaultTitle}` : defaultTitle}
      />
      <meta name="twitter:description" content={metaDescription} />
      {/* <meta
        name="twitter:image"
        content={data.markdownRemark.frontmatter.image.publicURL}
      /> */}
      {children}
    </>
  );
}

export default Seo;
