import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';

const Index = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const post =
    posts[Math.floor(Math.random() * (posts.length - 1 - 0 + 1) + 0)].node;

  return (
    <Layout>
      <article key={post.id}>
        <div>
          {post.frontmatter.image && (
            <div className="vimeo-wrapper">
              <GatsbyImage
                style={{ width: '100%' }}
                image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={`oriane dechéry ${post.frontmatter.title}`}
              />
            </div>
          )}
        </div>
        <div>
          {post.frontmatter.vimeo && (
            <div className="vimeo-wrapper">
              <iframe
                src={`https://player.vimeo.com/video/${post.frontmatter.vimeo}?background=1&autoplay=1&loop=1&byline=0&title=0`}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>
          )}
        </div>
        <div>
          {post.frontmatter.vimeocontain && (
            <div className="vimeo-contain">
              <iframe
                src={`https://player.vimeo.com/video/${post.frontmatter.vimeocontain}?controls=1&autoplay=1&loop=1`}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          )}
        </div>
        {/* {post.frontmatter.soundcloud && (
          <div
            className="hidden"
            dangerouslySetInnerHTML={{ __html: post.frontmatter.soundcloud }}
          />
        )} */}
        <div>
          {post.frontmatter.sound && (
            <audio
              class="fixed b0"
              autoplay
              controls
              src={post.frontmatter.sound.publicURL}
            ></audio>
          )}
        </div>
      </article>
    </Layout>
  );
};
export default Index;
export const Head = () => <Seo />;

export const indexQuery = graphql`
  query Index {
    allMarkdownRemark(
      filter: { frontmatter: { draft: { eq: false } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
            vimeo
            vimeocontain
            soundcloud
            sound {
              publicURL
            }
          }
        }
      }
    }
  }
`;
